.login-bg {
  /* background: url(../../assets//image//login-bg.jpg); */
  background-color: #f4f4f4;
  width: 100%;
  height: 100vh;
}
.login-bg img {
  width: 100%;
}
.card-login h3 {
  margin-bottom: 36px;
}
.login_form {
  height: 100px;
  width: 100px;
  color: white;
}
.card-login {
  background: white;
  padding: 46px 48px;
  border-radius: 9px;
  box-shadow: -8px 10px 8px #cfcfcf;
  margin-top: 97px;
  border-top: 3px solid red;
  border-bottom: 3px solid red;
}
.login-input {
  margin-bottom: 18px;
}
.login_btn {
  background-color: #ef5e62;
  border-radius: 9px;
  color: white;
}
.login_btn button {
  border-radius: 9px;
  color: white;
}
.instaBlood_logo img {
  width: 36%;
  margin-bottom: 14px;
}
